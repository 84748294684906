import { PanelProps } from '@grafana/data';
import * as d3All from 'd3';
import * as d3Sankey from 'd3-sankey';
import { cloneDeep, debounce, isUndefined } from 'lodash';
import * as React from 'react';
import { Graph, Link, LinkExtraProperties, NodeExtraProperties, SankeyDataNodes, SankeyDiagram } from './SankeyDiagram';

const d3 = {
  ...d3All,
  ...d3Sankey,
};

type timestamp = number;
type DataNodes = { [id: string]: DataNode };
type DataLinksPerTime = { [grouping: string]: DataLink[] };

interface DataResponse {
  nodes: DataNodes;
  links: DataLinksPerTime;
}

interface DataLink {
  source: string;
  target: string;
  value: number;
}

interface DataNode {
  id: string;
  label: string;
  sortIndex: number;
}

type SankeyPerTime = Map<timestamp, Graph>;

export const Sankey: React.FC<PanelProps> = ({ options, data, height, width }) => {
  const [sankeysPerLevel, setSankeysPerLevel] = React.useState<SankeyPerTime | null>(null);
  const [hoveredTime, setHoveredTime] = React.useState<number | null>(null);
  const onHoveredTimeChanged = React.useMemo(
    () =>
      debounce((e: CustomEvent) => {
        const panelsHoveredTime = e.detail.time;
        if (isUndefined(hoveredTime)) {
          return;
        }

        setHoveredTime(panelsHoveredTime);
      }, 100),
    [hoveredTime, setHoveredTime]
  );

  React.useEffect(() => {
    window.addEventListener(
      'click-time-changed',
      onHoveredTimeChanged as any as EventListener // https://github.com/Microsoft/TypeScript/issues/28357
    );
  }, [onHoveredTimeChanged]);

  React.useEffect(
    () => () => {
      window.removeEventListener('click-time-changed', onHoveredTimeChanged as any as EventListener);
    },
    [onHoveredTimeChanged]
  );

  React.useEffect(() => {
    if (data.series.length !== 1) {
      throw new Error('Expected one series');
    }

    const createSankey = d3
      .sankey<NodeExtraProperties, LinkExtraProperties>()
      .nodeId((node) => node.id)
      //.nodeAlign(d3.sankeyRight)
      .nodeAlign(d3.sankeyLeft)
      .iterations(30) // sets the number of relaxation iterations when generating the layout and returns this Sankey generator (default is 6)
      //.nodeSort((a, b) => b.value! - a.value!)
      .nodeSort((a, b) => b.sortIndex - a.sortIndex)
      .nodeWidth(5)
      .nodePadding(20)
      .extent([
        [0, 10],
        [width, height - 10],
      ]);

    console.log(options);

    const es = data.series[0];
    const from = (es.fields.find((f) => f.name === options.from)!.values as any).buffer as string[];
    const to = (es.fields.find((f) => f.name === options.to)!.values as any).buffer as string[];
    const sessions = (es.fields.find((f) => f.name === options.split)!.values as any).buffer as string[];
    const timesteps = (es.fields.find((f) => f.name === options.timestamp)!.values as any).buffer as number[];
    const count = (es.fields.find((f) => f.name === 'Count')!.values as any).buffer as number[];
    const nodes: Set<string> = new Set(from);
    to.forEach((n) => nodes.add(n));

    // bring single arrays together to form a single object with attributes that we can sort afterwards
    const dataObjects: Array<{ from: string; to: string; session: string; timestep: number; count: number }> = from.map(
      (f, idx) => ({
        from: f,
        to: to[idx],
        session: sessions[idx],
        timestep: timesteps[idx],
        count: count[idx],
      })
    );

    const roundFromTo = true;
    if (roundFromTo) {
      dataObjects.forEach((dao) => {
        const fn = parseFloat(dao.from);
        const tn = parseFloat(dao.to);
        dao.from = !!isNaN(fn) ? dao.from : Math.round(fn - 0.01).toString(); // -0.01 to make sure that we round 10.5 to 10
        dao.to = !!isNaN(tn) ? dao.to : Math.round(tn - 0.01).toString();
      });
    }

    const sessionsToLevel: Map<string, number> = new Map();

    // sort events by timestep
    dataObjects.sort((a, b) => a.timestep - b.timestep);

    const limitLevels = 6;
    let maxLevel = 0;

    const groups: { [id: string]: DataLink[] } = {};
    for (const dao of dataObjects) {
      const level = sessionsToLevel.get(dao.session) ?? 0;

      if (level > limitLevels) {
        continue;
      }

      sessionsToLevel.set(dao.session, level + 1);

      const groupKey = 0; // level;
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }

      const sourceKey = `${level}_${dao.from}`;
      const targetKey = `${level + 1}_${dao.to}`;

      // check if element already exists
      const element = groups[groupKey].find((d) => d.source === sourceKey && d.target === targetKey);
      if (!!element) {
        // if same path already exist, just add values
        element.value += dao.count;
      } else {
        // otherwise add new path / element
        groups[groupKey].push({
          source: sourceKey,
          target: targetKey,
          value: dao.count,
        });
      }

      if (level > maxLevel) {
        maxLevel = level;
      }
    }

    const dataNodes: DataNodes = {};
    const sankeysPerLevel = new Map();

    const createSortIndex = (value: any) => {
      const n = parseFloat(value);
      if (!isNaN(n)) {
        return n;
      } else {
        const periods = [
          'periodLastArchive',
          'periodLast48h',
          'periodLast24h',
          'periodLast12h',
          'periodLast6h',
          'periodCurrentLowRes',
          'periodCurrentHighRes',
          'periodCurrent',
          'periodPrognose24h',
          'periodTomorrow',
          'periodDayThree',
          'periodDayFour',
        ].reverse();
        const layers = ['wr', 'rr', 'temp', 'gust', 'profi', 'webcam', 'lightning'].reverse();
        const periodIndex = periods.indexOf(value);
        const layerIndex = layers.indexOf(value);

        if (periodIndex !== -1) {
          return periodIndex;
        } else if (layerIndex !== -1) {
          return layerIndex;
        }
      }
      return 0;
    };

    // create data nodes for all levels that are available
    for (let idx = 0; idx <= maxLevel + 1; idx++) {
      Array.from(nodes).forEach((n) => {
        const id = `${idx}_${n}`;
        dataNodes[id] = { id, label: n, sortIndex: createSortIndex(n) } as DataNode;
      });
    }

    const sankeyData: DataResponse = {
      nodes: dataNodes,
      links: {
        ...groups,
      },
    };

    // console.log(sankeyData);

    Object.entries(sankeyData.links).forEach(([timestamp, links]) => {
      sankeysPerLevel.set(
        parseInt(timestamp, 10),
        createSankey({
          nodes: getNodesForLinks(sankeyData.nodes, links),
          links: cloneDeep(links as Link[]),
        })
      );
    });

    setSankeysPerLevel(sankeysPerLevel);
  }, [data.series, width, height]);

  if (data.state === 'Error') {
    return <div>Error</div>;
  }

  if (sankeysPerLevel === null) {
    return <span>Initializing data</span>;
  }

  const level = hoveredTime === null ? Array.from(sankeysPerLevel.keys()).pop()! : hoveredTime / 1000;
  const sankey = sankeysPerLevel.get(level);
  if (isUndefined(sankey)) {
    return <>¯\_(ツ)_/¯</>;
  }

  return (
    <>
      <SankeyDiagram sankey={sankey} dimensions={{ width, height }} />
    </>
  );
};

const getNodesForLinks = (nodes: DataNodes, links: DataLink[]) => {
  const linkIds = new Set();
  for (const link of Object.values(links)) {
    linkIds.add(link.source);
    linkIds.add(link.target);
  }

  return cloneDeep(
    Object.values(
      Object.keys(nodes)
        .filter((key) => linkIds.has(key))
        .reduce<SankeyDataNodes>((acc, key) => {
          acc[key] = nodes[key];

          return acc;
        }, {})
    )
  );
};
