// @ts-ignore
import { PanelPlugin } from '@grafana/data';
import { SimpleEditor } from 'SimpleEditor';
import { Sankey } from './sankey';

export const plugin = new PanelPlugin(Sankey).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'from',
      path: 'from',
      name: 'From',
      description: 'Field to use where lines start',
      editor: SimpleEditor,
    })
    .addCustomEditor({
      id: 'to',
      path: 'to',
      name: 'To',
      description: 'Field to use where lines end',
      editor: SimpleEditor,
    })
    .addCustomEditor({
      id: 'split',
      path: 'split',
      name: 'Split by',
      description: 'Field to use for splitting data into different levels',
      editor: SimpleEditor,
    })
    .addCustomEditor({
      id: 'timestamp',
      path: 'timestamp',
      name: 'Timestamp',
      editor: SimpleEditor,
    });
});
