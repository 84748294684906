import React from 'react';
import { Select } from '@grafana/ui';
import { SelectableValue, StandardEditorProps } from '@grafana/data';

export const SimpleEditor: React.FC<StandardEditorProps<string>> = ({ item, value, onChange, context }) => {
  const options: Array<SelectableValue<string>> = [];

  if (context.data) {
    if (context.data.length > 0) {
      const fields = context.data[0].fields;

      fields.forEach((f) => {
        options.push({
          label: f.name,
          value: f.name,
        });
      });
    }
  }

  return <Select options={options} value={value} onChange={(selectableValue) => onChange(selectableValue.value)} />;
};
